import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`👋 I'm a Fullstack Engineer`}</h1>
    <p>{`I'm Levi Smith and I have over 12 years of professional developer experience with a passion for UI/UX development with React/Typescript.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.foreversnipersvsrunners.com/"
      }}>{`Forever Snipers vs Runners 🏃`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      