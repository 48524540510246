import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I love to solve problems, improve processes, and just figure things out. I've worked on some really cool projects over the years but no matter where I am at or what I am working I take great pride in helping people with their technical problems.`}</p>
    <p>{`Some of the technologies and frameworks I am comfortable with:`}</p>
    <p>{`Typescript, JavaScript, C#, Python, Node.JS, Vite, CRA, Gatsby, Next.JS, Nest.JS, SQL, PHP, .NET`}</p>
    <p>{`Some of the things I am learning:`}</p>
    <p>{`Svelte, GO`}</p>
    <p>{`But more importantly, the real reason you are here, my favorites`}</p>
    <p>{`Movies = Scott Pilgrim Vs. the World, Spiderman: Into the Spiderverse, Gattaca`}</p>
    <p>{`Books = Harry Potter, The Lies of Locke Lamora, Off to Be the Wizard`}</p>
    <p>{`TV Shows = Breaking Bad, Better Call Saul, Stranger Things`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      