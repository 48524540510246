import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Unphrased" link="https://unphrased.io" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
    A challenging daily word/phrase game built in React, hosted on S3 with Cloudfront.
    </ProjectCard>
    <ProjectCard title="Generator Ninja" link="https://generator.ninja/" bg="linear-gradient(to right, #372e54 0%, #fff05a 100%)" mdxType="ProjectCard">
    Fantasy, sci-fi, animals, geography, words, names, and many more random generators to generate random fun! Built with
    GatsbyJS, React, and hosted on S3 with Cloudfront.
    </ProjectCard>
    <ProjectCard title="Slowroll Poker Timer" link="https://slowroll.io/" bg="linear-gradient(to right, #ff328b 0%, #0cea85 100%)" mdxType="ProjectCard">
    Free poker timer for tournaments, cash games, or just for fun. Increasing blind levels, custom sounds, and more! Built
    with Next.js and hosted on S3 with Cloudfront.
    </ProjectCard>
    <ProjectCard title="Many More..." link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
    I've created many sites and businesses over the years with various technologies: HTMl, CSS, JavaScript/Typescript, PHP,
    NodeJS, React, MongoDB, Firebase, PostgreSQL, and more.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      